import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '@app/store/models';
import { Lang } from '@app/i18n/lang.type';
import { ProductDescription } from '../models';

export const productActions = createActionGroup({
    source: 'Product',
    events: {
        'Get Product Details': props<{ id: string; lang: Lang }>(),
        'Get Product Details Success': props<{ product: Product }>(),
        'Get Product Details Error': emptyProps(),

        'Get Product Description': props<{ productId: string; lang: Lang }>(),
        'Get Product Description Success': props<{ value: ProductDescription }>(),
        'Get Product Description Error': emptyProps(),

        'Open Product Card': props<{ productNo: string; lang: Lang }>(),
        'Open Product Card Success': props<{ blob: Blob }>(),
        'Open Product Card Error': emptyProps(),

        Clear: emptyProps(),
    },
});
